.background_clr {
    background-color: #fbdbe1;
    height: 55vh;
}

.input-group-text {
    border: none !important;
}

.agree_font {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
}

.help_font {
    font-size: 20px;
    font-weight: 700;
}

.shadow {
    box-shadow: 0 0 0 .25rem rgb(248 250 253 / 25%) !important;
}