@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Inter' !important;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #888383;
  border-radius: 5px;
}

.modal::-webkit-scrollbar {
  width: 12px;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #888383;
  border-radius: 5px;
}

.ModalMain_Style {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* background: linear-gradient(180deg, rgba(203, 204, 205, 0.4) 0%, rgba(193, 194, 195, 0.4) 100%), linear-gradient(270deg, #d0d0d2 38.08%, #bdbfc0 78.1%, #c9c9ca 103.12%), linear-gradient(180deg, #b7b7b8 0%, #c2c3c4 100%); */
}