.form-control:focus {
    background-color: #fff !important;
    border-color: #B24BD6 !important;
    box-shadow: 0 0 0 .25rem rgb(248 250 253 / 25%) !important;
}

.form-control {
    border: var(--bs-border-width) solid #B24BD6
}


.Dropdown_Back_Color {
    /* background-color: #EDF2F7 !important; */
    border: 1px solid #B24BD6;
    border-radius: 5px;
}

.need_size {
    font-size: 13px;
    color: #B24BD6;
    cursor: pointer;
}

.Button_Font {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    border: none;
    padding: 8px;
    width: 100%;
    border-radius: 4px;
}

.Back-Image {
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

.Vertical_Main {
    padding: 25px 50px;
    background-color: #fff;
    border-radius: 10px;
}

.Signup_Font {
    color: var(--black-25, #252525);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.KP_Font {
    color: var(--Secondary-Text, #252525);
    font-size: 17px;
    font-weight: 600;
}

.head_size {
    font-size: 15px;
    font-weight: 500;
    color: #B24BD6;
}

.height_set {
    height: 76vh;
}

.height_sete {
    height: 70vh;
}

.Signup_height {
    height: 66%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
}

.Label_Font {
    color: var(--Primary-Text, var(--black-25, #252525));
    font-family: 'SF Pro Display', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
}

.Signup_height::-webkit-scrollbar {
    width: 6px;
}

.Signup_height::-webkit-scrollbar-thumb {
    background-color: #616161 !important;
    border-radius: 12px;
}

.Otp-font {
    font-size: 17px;
    color: var(--black-25, #252525);
    font-weight: 400;
}

.Modal_inside {
    background-color: #fff;
    border-radius: 10px;
}

.modal-header {
    border-bottom: none !important;
    padding: 15px 16px 10px 16px !important
}

.signupBackie {
    background-image: url(../components/Assets/311a55b1e12f8989b8a986b6dff829a5.jpg);

}

.loginbg {
    background-color: #fff9f9;
    border: 0.5px solid #f6e6e6;
}

.profile_image {
    width: 35px;
    border-radius: 50%;
    height: 35px;
}

.css-t3ipsp-control {
    border: 1px solid #B24BD6 !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover {
    border: #B24BD6 1px solid !important;
}

.IconColor_Green {
    color: #009e54;
}

.Verify_Style_Green {
    color: #009e54;
    font-family: "SF Pro Display", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.about-text {
    width: 65%;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: #5C616C;
    text-align: justify;
    margin-top: 1%;
}

.we-support {
    font-size: 22px;
    font-weight: 600;
}

/* .background_img{
    background-image: url(./Assets/Mask\ Group.png);
    width: 50%;
    height: 50%;
} */
.captial {
    text-transform: capitalize;
}

.search_background {
    /* background-image: url(../components//Assets/Frame\ 1000007196.png); */
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-image: url('path-to-your-image.jpg'); */
    background-size: cover;
    background-position: center;
    filter: drop-shadow();
    /* Apply blur to the background image */
    overflow: hidden;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.suggestionList {
    padding: 5px;
    border-radius: 5px;
    color: #c76776;
}

.error-message {
    color: red;
}

.language_size {
    color: #fff;
    font-size: 14px;

}

.modal-body {
    padding: 0px !important;
}

.modal-body-share{
    padding: 23px 30px !important;
}

.sideimg_size {
    width: 515px;
    margin-left: -82px;
    height: 550px;
}

.full-size-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.full-height-col {
    height: 541.5px;
}

.modal {
    overflow-y: hidden !important;
}

.footer_back {
    background-color: #000;
    height: fit-content;
}

.footer_head {
    color: #fff;
}

.Footer_insidefont {
    font-size: 14px;
    font-weight: 500;
    color: #BFC8CD;
}

.height_inherit {
    height: inherit;
}

.search_top {
    color: #fff;
    font-size: 37px;
    font-weight: 300;
    width: 56%;
}

.ad_carosuel {
    height: fit-content;
    background: #b7b9c259;
    /* overflow-x: scroll; */
}

.inside_card {
    /* border: 1px solid ; */
    box-shadow: 0 0 0 .25rem rgb(133 135 137 / 22%);
    border-radius: 10px;
    background: #fff;
}

.head_font {
    font-size: 11px;
    font-weight: 800;
}

.subhead {
    font-size: 8px;
    font-weight: 600;
}

.content_font {
    font-size: 9px;
    font-weight: 500;
}

.today_font {
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    text-align: start;
    width: 100%;
}

.left_arrow_search {
    position: relative;
    bottom: -38%;
    left: 1%;
    border: 0.5px solid #fff;
    background: white;
    border-radius: 5px;
}

.right_arrow_search {
    position: relative;
    border: 0.5px solid #fff;
    background: white;
    border-radius: 5px;
    right: -97%;
    bottom: 35%;
}

.bookmark_label {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    position: relative;
    right: 53px;
    bottom: 55px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.bookmark-Div{
    position: absolute;
    width: 25%;
    background-color: #fff;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 16%); */
    right: 1%;
    top: 8%;
    z-index: 9;
    border-radius: 10px;
    border: 0.5px solid #B24BD6;
    
}

.bookmark_height{
    overflow-y: scroll;
    height: 50vh;
    overflow-x: hidden;
}

.bookmark_height::-webkit-scrollbar {
    width: 6px;
}

.bookmark_height::-webkit-scrollbar-thumb {
    background-color: #616161 !important;
    border-radius: 12px;
}

.nav-pills .nav-link.active {
    background-color: #B24BD6;
    white-space: nowrap;
    text-align: center
}

.nav-pills .nav-link.active:hover{
    color: #fff;
}

.nav{
    --bs-nav-link-color: #B24BD6 !important;
}

.nav-link:hover{
    color: #B24BD6 ;
}

.nav-pills .nav-link {
    text-align: center;
    border: 0.5px solid#e3e2e2;
}

.url_font{
    font-Size: 12px; 
    font-Weight: 500;
    cursor: pointer; 
    width: 278px ;
}

.buttonnew_font{
   border-radius: 5px;
   border: 0.5px solid #B24BD6;
   font-size: 12px;
}

.historydeletecard{
    position: absolute;
    width: 70px;
    background-color: #fff;
    right: 3.5%;
    top: 27%;
    z-index: 9;
    border-radius: 4px;
    border: 0.5px solid #000;
}

.margin_back{
    margin-left: -12px;
}

.clearall_button{
    border-radius: 5px;
    background-color: #B24BD6;
    color: #fff;
    font-size: 12px;
    border: none;
}

.input_border{
    border: 1px solid #000;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
}