.headBackie {
    /* background-color: #fff; */
    /* box-shadow: 5px 10px #c90a0a; */
    /* border:1px solid #dfdcdc; */
    position: absolute;
    width: 100%;
}

.headBackie_Ho {
    background-color: #fff;
    /* box-shadow: 5px 10px #c90a0a; */
    /* border: 1px solid #dfdcdc; */
    position: fixed;
    z-index: 999;
    width: 100%;
}


.loginbtn {
    border-radius: 5px;
    background-color: #B24BD6;
    color: #fff;
    padding: 6px 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    font-size: 12px;
    height: 28px;
}

.web {
    display: block;
}

.mobi {
    display: none;
}

@keyframes slideIn {
    from {
        left: -100%;
    }

    to {
        left: 21%;
    }
}

@keyframes slideOut {
    from {
        left: 21%;
    }

    to {
        left: -100%;
    }
}


@media (max-width: 768px) {
    .web {
        display: none;
    }

    .mobi {
        display: block;
    }

    .Nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: -100%;
        /* Start off-screen */
        background-color: #f8f9fa;
        border: 1px solid #ddd;
        padding: 10px;
        transition: left 0.5s ease-in-out;
        /* For smooth transition */
        z-index: 999;
    }

    .Nav.slide-in {
        animation: slideIn 0.5s forwards;
        /* Apply the slide-in animation */
    }

    .Nav.slide-out {
        animation: slideOut 0.5s forwards;
        /* Apply the slide-out animation */
    }
}

.Arrow-Div {
    position: absolute;
    width: 253px;
    background-color: #fff;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 16%); */
    right: 3%;
    top: 53px;
    z-index: 9;
    border-radius: 10px;
    border: 0.5px solid #B24BD6;
}

.settingcard{
    position: absolute;
    width: 200px;
    background-color: #fff;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 16%); */
    right: 3%;
    top: 53px;
    z-index: 9;
    border-radius: 10px;
    /* border: 0.5px solid #B24BD6; */
}

.font_size {
    font-size: 13px;
    font-weight: 500;
}

.profile_size {
    font-size: 16px;
    font-weight: 600;
}

.Logout_btn {
    border-radius: 6px;
    border: 0.5px solid #B24BD6;
    background-color: #fff;
    font-size: 14px;
    margin-top: 4%;
}

.Logout_btn:hover {
    border-radius: 6px;
    border: 0.5px solid #B24BD6;
    background-color: #B24BD6;
    color: #fff;
    font-size: 14px;
    margin-top: 4%;
}