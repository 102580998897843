.firstie_hoem {
    /* background-image: url(./components/Assets/qqq123.png); */
    background-image: url(./components/Assets/qqq.png);
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
}

.roundCircleImg {
    width: 70%;
    height: 70%;
}

.roundCircleImg1 {
    width: 90%;
    height: 90%;
}

.secondScale {
    background: linear-gradient(270deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.sedondScaletwo {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    width: 445.72px;
    background: #0E0E0F;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thirdCardScale {
    width: 78%;
    height: 250px;
    border: 1px 0px 0px 0px;
    /* border: 1px solid #CFD1D7; */
    background-color: #fafbfc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.cornerbackieo {
    background: #B24BD6;
}

.cornerimgbackio {}


.cardSD-container {
    display: flex;
    gap: 50px;
}

.cardSD {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    width: 150px;
}

.cardSD img {
    width: 100%;
    height: auto;
    padding: 12px;
}

.cardSD-title {
    background-color: #9c27b0;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
}

.headCol {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    color: #0E0E0F;
    text-transform: uppercase;
}

.subCol {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #0E0E0F;
}


/* src/SarojiniComponent.css */
.sarojini-content {
    display: flex;
    align-items: center;
    background-color: #3b1d5a;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sarojini-image {
    max-width: 50%;
    border-radius: 10px;
}

.sarojini-text {
    color: white;
    margin-left: 20px;
}

.sarojini-text h2 {
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    padding: 0 10% 0 5%;
}

.sarojini-text p {
    font-size: 16px;
    font-weight: 400;
    text-align: justified;
    padding: 0 10% 0 5%;
}

.productour {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(270deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.olSection {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: #252525;
    margin-top: -10px;
}

.download_BackGround {
    background: linear-gradient(180deg, #B24BD6 0%, #5D2770 100%);
    padding: 5% 0 10% 0;
}

.downloadHead {
    display: flex;
    justify-content: center;
    font-size: 38px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    color: #FFFFFF;
    padding: 5% 0;
}

.download_second_back {
    background: #FFFFFF;
    width: 85%;
    padding: 5% 0;
    border-radius: 10px;
}

.downloadSection_content {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: justified;
    color: #252525;
    text-align: justify;
    width: 400px;
}

.dividder {
    width: 0px;
    height: 232px;
    top: 3036px;
    left: 853px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    angle: 90 deg;
    border: 1px solid #B24BD6
}

.outrustHead {
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    text-align: center;
    background: linear-gradient(270deg, #5BA3B0 0%, #62138E 60%, #582F6B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.outrustsubContetn {
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    color: #696969;
}


.logo-gallery {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    gap: 60px;
}

.logo-item {
    text-align: center;
}

.logo-image {
    width: 100px;
    /* Adjust as needed */
    height: auto;
    display: block;
    margin: 0 auto;
}

.logo-name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    text-align: justified;
    background: linear-gradient(90deg, #B24BD6 0%, #5D2770 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Customize the color as needed */
}

.col-6 {
    flex: 0 0 auto;
    width: 48% !important;
}