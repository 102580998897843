/* cursor */
.pointer {
  cursor: pointer;
}

/* cursor */

.hoeBa_kie {
  background-color: #FFF;
}

.InputContainer {
  height: 50px;
  min-width: 580px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 15px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000026;
}

.search_history{
  height: 50px;
  min-width: 580px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 15px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000026;
}



.InputContainer1 {
  height: 42px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 15px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000026;
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  /* font-size: 0.9em; */
  margin-left: 30px;
}

.global_search_icon {
  color: #919090;
  position: relative;
  left: 36px;
}

.global_search_icon1 {
  color: #919090;
  position: relative;
  left: 7%;
}

.input_size {
  width: 300px;
}

.input_size1 {
  width: 500px;
}

.labelforsearch {
  cursor: text;
  padding: 0px 5px 5px 12px;
}

.searchIcon {
  width: 13px;
}

.border {
  height: 20px !important;
  width: 1.3px !important;
  background-color: rgb(223, 223, 223) !important;
}

.micIcon {
  width: 12px;
}

.micButton {
  padding: 5px 5px 0px 12px;
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: .3s;
}

.searchIcon path {
  fill: rgb(114, 114, 114);
}

.micIcon path {
  fill: rgb(255, 81, 0);
}

.searchButton {
  color: white;
  right: 8px;
  /* height: 48px; */
  background: #B24BD6;
  border: 0;
  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  margin-left: 10px;
  padding: 10px;
  border-radius: 50%;
}

.margin-no {
  margin-left: 0px !important;
}

.icon_padding {
  padding: 3px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000026;
  background-color: #fff;
}

.font_name {
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  color: #EF8294;
  text-shadow: 2px 2px #dcdada;
}

.img_resizing {
  width: 26px;
}

.img_sizing {
  width: 16px;
  height: 16px;
}

.img_resizing {
  width: 26px;
}

.list_align {
  width: 15% !important;
  position: absolute;
  left: 37%;
  top: 20%;
}

/* second */
.paddSearchIcon {
  padding: 60px 4px 4px 4px;
}

.search_imgsize {
  width: 22px;
  height: 22px;
}

.headCollio {
  background-color: #f1f1f1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.fullViewHeight {
  background-color: #fff;
  border-radius: 10px;
  /* height: 78vh; */
  border: 1px solid #dcdcdc;
}

.list_align {
  width: 15% !important;
  position: absolute;
  left: 45%;
  top: 25%;
}

.adHeight {
  height: 230px;
}

.iframe_height {
  height: 77vh;
  border-radius: 0px 0px 10px 10px;
  overflow-y: scroll;
  padding: 5px 8px;
  border-radius: 0px 10px 10px 0px;
}

.iframe::-webkit-scrollbar-thumb {
  background-color: rgb(193, 36, 36) !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px !important;
  /* Roundness of the scrollbar thumb */
  border: 3px solid #ffffff !important;
}

/* second */

.row {
  margin: 0px !important;
}

.search-engine-list {
  max-width: 170px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
}

.search-box {
  display: flex;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  justify-content: flex-end;
  background-color: #f4f2f2;
  gap: 4px;
}

.search-box input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
}

.engine-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 150px;
  overflow-y: auto;
}


.engine-list::-webkit-scrollbar {
  width: 5px !important;
}

.engine-list::-webkit-scrollbar-thumb {
  background: #676565 !important;
  border-radius: 6px !important;
}

.engine-item {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  color: #000;
  font-size: 13px;
}

.engine-item:hover {
  background-color: #f9f9f9;
}

.engine-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.Dropdown_input {
  width: 50%;
  padding: 3px 6px !important;
  font-size: 13px !important;
  border-radius: 3px;
}

.back_color {
  padding: 3px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #B24BD6;
  box-shadow: 0px 4px 4px 0px #00000026;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.iframe_height {
  /* height: 70vh; */
  border-radius: 0px 0px 10px 10px;
  overflow-y: scroll;
  padding: 5px 8px;
  border-radius: 0px 10px 10px 0px;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.iframe::-webkit-scrollbar-thumb {
  background-color: rgb(193, 36, 36) !important;
  /* Color of the scrollbar thumb */
  border-radius: 10px !important;
  /* Roundness of the scrollbar thumb */
  border: 3px solid #ffffff !important;
}

input {
  font-family: 'Inter' !important;
}

.second_card {
  display: flex;
  align-items: center;
}

.h5,
h5 {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

.Nav {
  display: flex;
  gap: 20px;
  margin-left: -75px;
  padding: 7px 0px;
}

.Nav a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  padding: 2px 10px;
}

.Nav a:hover,
.Nav .active {
  background-color: #B24BD6;
  border-radius: 20px;
  color: #fff;
}

.Navssss {
  display: flex;
  gap: 20px;
  margin-left: -75px;
  padding: 7px 0px;
}

.Navssss a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #B24BD6;
  padding: 2px 10px;
}

.Navssss a:hover,
.Navssss .active {
  background-color: #B24BD6;
  border-radius: 20px;
  color: #fff;
}


.largeContent {
  gap: 0px;
  font-size: 42px;
  font-weight: 600;
  text-align: left;
  flex-wrap: nowrap;
  color: #fff;
}

.smallContent {
  width: 65%;
  /* height: 160px; */
  gap: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: justified;
  color: #fff;
  text-align: justify;
  margin-top: 1%;

}

.mainBtn {
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #fff;
  color: #B24BD6;
  width: max-content;
  padding: 6px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.mainBtns {
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #EF8294;
  color: #fff;
  padding: 6px 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.slideImg {
  width: 175px;
  height: 175px;
}

.arrowIcon {
  display: flex;
  align-items: center;
  /* height: 140px; */
  cursor: pointer;
}


.web {
  display: block;
}

.mobi {
  display: none;
}

@media (max-width: 768px) {
  .Nav {
    flex-direction: column;
    align-items: center;
  }

  .dictionaryICon {
    position: absolute;
    right: 10%;
  }


  .slideImg {
    width: 145px;
    height: 130px;
  }

  .web {
    display: none;
  }

  .mobi {
    display: block;
  }

  .largeContent {
    font-size: 38px;
  }

  .smallContent {
    font-size: 16px;
    width: fit-content;
  }
}


@media (max-width: 600px) {
  .search-engine-list {
    width: 90%;
  }

  h5 {
    display: none;
  }

  .second_card {
    display: flex;
    flex-direction: column;
    margin-top: 10px !important;
  }

  .InputContainer1 {
    height: 42px;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;
    padding-left: 15px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 4px 0px #00000026;
  }

}